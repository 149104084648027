<template>
    <div class="card">
        <div class="bg-blue-light p-1">
            <TitleButton
              :showBtn="false"
              :showAddNew="true"
              :btnTitle="'Insert'"
              @onClickAddNewButton="insertIntoTable"
              title="Upload Contacts"
            />
            <input type="file" class="d-none" ref="uploader" @change="handleFileUpload">
        </div>
        
        <div class="p-2">
            <template v-for="(item, key) in csvData" :key="key">
                <div v-if="! uploadedBatches.includes(item.key)">
                    <div :class="key != 0 ? 'mt-3 d-flex' : 'd-flex'">
                        <h4>Batch: {{ item.key }} </h4>
                        <div v-if="item.key === uploadingBatch" class="spinner-border text-success mx-2" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    <div class="px-2 pt-2">
                        <div v-for="(child, index) in item.data" class="d-flex" :key="index">
                            <p :class="{'text-danger' : child.data.status === 'error'}">
                                {{ getTitle(child.data) }}
                            </p> 
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script setup>
    import { ref, onMounted, watch } from 'vue';
    import handleInventory from "@/services/modules/inventory";
    import {useRoute, useRouter} from 'vue-router';
    import TitleButton from '@/components/atom/TitleButton.vue';
    import handleCompanyContact from "@/services/modules/companyContact";
    const route = useRoute();
    const router = useRouter();

    const { fetchCsvData } = handleInventory();
    const { insertContacts } = handleCompanyContact();

    const csvData = ref([]);
    const uploadingBatch = ref('');
    const uploadedBatches = ref([]);

    onMounted(() => {
        let query = `?id=${route.query.rowId}&company_id=${route.params.companyId}`;

        fetchCsvData(query).then((res) => {
            if(! res.status) return

            csvData.value = Object.keys(res.data).map((key) => ({
                key,
                data: res.data[key],
            }));
        })
    })

    const insertIntoTable = () => {
        let index = 0;
        uploadProduct(index);
    }

    const getTitle = (data) => {
        let title = `${data.full_name} (${data.serial_no})`;

        if(data.phone){
            title += ` - ${data.phone}`;
        }

        if(data.email){
            title += ` - ${data.email}`;
        }

        return title;
    }

    const uploadProduct = async (length) => {
        if(length == csvData.value.length) {
            router.back();
        }

        const formData = {
            company_id: route.params.companyId,
            id: route.query.rowId,
            batch: csvData.value[length].key
        }

        uploadingBatch.value = csvData.value[length].key;

        await insertContacts(formData).then((res) => {
            if(res.status) {
                uploadedBatches.value.push(uploadingBatch.value)
                uploadingBatch.value = '';

                setTimeout(() => {
                    length++;
                    uploadProduct(length)
                },5000)
            }
        })
    }
    
</script>